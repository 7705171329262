import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/Header";
import { NavLink } from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import PrivacyBookwormsNetwork from "privacy/PrivacyBookwormsNetwork";
import PrivacyBullsAndCows from "privacy/PrivacyBullsAndCows";
import PrivacyDadJokeMessenger from "privacy/PrivacyDadJokeMessenger";
import PrivacyInDomain from "privacy/PrivacyInDomain";
import PrivacyMomMal from "privacy/PrivacyMomMal";
import PrivacyWouldSay from "privacy/PrivacyWouldSay";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  const { serviceName } = useParams();
  const content = serviceName ? (
    {
      mommal: <PrivacyMomMal />,
      "dad-joke-messenger": <PrivacyDadJokeMessenger />,
      "bulls-and-cows": <PrivacyBullsAndCows />,
      "in-domain": <PrivacyInDomain />,
      "bookworms-network": <PrivacyBookwormsNetwork />,
      wouldsay: <PrivacyWouldSay />,
    }[serviceName.toLowerCase()]
  ) : (
    <>
      <h2>Click to see the privacy policy of our services.</h2>
      <br />
      <ul>
        <li>
          <NavLink href="/privacy/mommal">MomMal</NavLink>
        </li>
        <li>
          <NavLink href="/privacy/dad-joke-messenger">
            Dad Joke Messenger
          </NavLink>
        </li>
        <li>
          <NavLink href="/privacy/bulls-and-cows">BullsAndCows</NavLink>
        </li>
        <li>
          <NavLink href="/privacy/in-domain">InDomain</NavLink>
        </li>
        <li>
          <NavLink href="/privacy/bookworms-network">Bookworms Network</NavLink>
        </li>
        <li>
          <NavLink href="/privacy/wouldsay">WouldSay</NavLink>
        </li>
      </ul>
    </>
  );
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>{content}</Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
