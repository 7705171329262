import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ThreeColSlider from "components/cards/ThreeColSlider";

const Header = tw(HeaderBase)`max-w-none`;
const HighlightedText = tw.span`text-primary-500`


export default () => {
    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="/">Home</NavLink>
            <NavLink href="/products">Products</NavLink>
            <NavLink href="/about">About</NavLink>
            {/* <NavLink href="/#">Blog</NavLink> */}
            {/* <NavLink href="/#">Pricing</NavLink> */}
            <NavLink href="/contact">Contact Us</NavLink>
            {/* <NavLink href="/#">Testimonials</NavLink> */}
        </NavLinks>,
        // <NavLinks key={2}>
        //   <NavLink href="/#" tw="lg:ml-12!">
        //     Login
        //   </NavLink>
        //   <PrimaryLink css={buttonRoundedCss} href="/#">
        //     Sign Up
        //   </PrimaryLink>
        // </NavLinks>
    ];
    return (
        <Header links={navLinks} />
    );
}
